<template>
  <div>
    <div class="fonsBlanc_light">
      <div class="container"   style="margin-top: 0px;">
        <div class="col-lg-12">
          {{$t('__sou_a__')}}: <a class="breadcrumb" @click="redireccionInicio()">{{$t('Inici')}}</a>&nbsp;>&nbsp;{{$t('Resultats de la cerca')}}        

          <div v-if="data">
            <!-- <div v-if="dataUnitat"> -->
              <div class="col-lg contenidorTitolSeccio">
                <div class="titolSeccio" style="color:#000;">{{dataUnitat['descripcio'+idioma+'_ac']}}</div>
                <hr style="margin-top:0px;margin-left:0px;" >
              </div>

              <div v-if="dataUnitat['descripcio_curta_ac'] || dataUnitat['idUnitat_txt']" style="margin-bottom:10px;">
                <span style="margin-left:32px;"></span>{{$t('__codi__')}} {{ dataUnitat['idUnitat_txt'] }}&nbsp;&mdash;&nbsp;{{ dataUnitat['descripcio_curta_ac'] }}
              </div>  
            <!-- </div> -->

            <table v-if="dataUnitat['edifici'+idioma+'_ac']">
              <row>
                <td style="padding-left:0px;vertical-align:middle;">
                  <i class="bi bi-geo-alt bi-gris" style="font-size:25px;margin-left: -2px;"/>
                </td>
                <td style="padding-left:4px;">
                  <div v-if="dataUnitat['campus'+idioma+'_ac']">
                    <span>&nbsp;{{dataUnitat["campus"+idioma+"_ac"] }}</span>
                  </div>                                 
                  <div v-if="dataUnitat['edifici'+idioma+'_ac']">
                    <span>&nbsp;{{dataUnitat["edifici"+idioma+"_ac"] }}</span>
                  </div>
                  <div v-if="dataUnitat.adreca_ac">
                    <span>&nbsp;{{ dataUnitat.adreca_ac }}</span>
                  </div>
                  <div v-if="dataUnitat.localitat_ac">
                    <span>&nbsp;{{dataUnitat.localitat_ac }}</span>
                  </div>
                  <div v-if="dataUnitat.codi_postal_txt">
                    <span>&nbsp;{{dataUnitat.codi_postal_txt }}</span>
                  </div>
                  <span>&nbsp;<a :href="'https://maps.app.goo.gl/'+dataUnitat.urlmap_txt" target="_blank" class="no-link descripcioDades">{{$t('__google_maps__')}}</a><a :href="'https://maps.app.goo.gl/'+dataUnitat.urlmap_txt" target="_blank"><span class="bi bi-box-arrow-up-right" style="margin-left: 0px;"></span></a></span><br>                 
                </td>
              </row>
            </table>
            <div v-if="dataUnitat.telefon_txt">
              <i class="bi bi-telephone bi-gris" style="font-size:18px"></i><span style="margin-left:15px;">{{ dataUnitat.telefon_txt }}</span>
            </div>
            <div v-if="dataUnitat.fax_txt">
              <i class="bi bi-printer bi-gris" style="font-size:18px"></i><span style="margin-left:15px;">{{ dataUnitat.fax_txt }}</span>
            </div>
            <div v-if="dataUnitat.url_txt">
              <i class="bi bi-link-45deg bi-gris" style="font-size:19px;"></i><span style="margin-left:15px;"></span><a :href="dataUnitat.url_txt" target="_blank" class="no-link descripcioDades">{{dataUnitat.url_txt}}</a><a :href="dataUnitat.url_txt" target="_blank"><span class="bi bi-box-arrow-up-right" style="margin-left: 0px;"></span></a><br>
            </div>
            <div v-if="dataUnitat.tipus_unitat_txt">
              <span style="margin-left:32px;"></span><a v-if="dataUnitat['idUnitat_txt']" :href="'https://www.upc.edu/content/unitat/fitxa/html?codi_unitat='+dataUnitat.idUnitat_txt+'&lang='+idiomaUrl+'&version=5'" target="_blank" class="no-link">{{$t('__missio_funcions__')}}</a>&nbsp;
              <a :href="'https://www.upc.edu/content/unitat/fitxa/html?codi_unitat='+dataUnitat.idUnitat_txt+'&lang=ca&version=5'" target="_blank"><span class="bi bi-box-arrow-up-right" style="margin-left: 0px;"></span></a><br>              
            </div>

            <div v-if="dataUnitat['idUTG_txt']">
              <i class="bi bi-diagram-3 bi-gris" style="font-size:22px;"></i>
              <a class="no-link" @click="getDadesUtg(dataUnitat['idUTG_txt'])" style="margin-left:10px;">{{$t('__utg__')}}</a><br>
            </div>              
            <br>                      
          
            <!-- CARRECS -->
            <div v-if="dataCarrecs || dataUnitat['descripcio_utg'+idioma+'_ac']" class="col-lg contenidorTitolSeccio">
              <div class="titolSeccio" style="color:#000;">{{$t('Carrecs')}}</div>
              <hr style="margin-top:0px;margin-left:0px;" >
              <div v-for="item in dataCarrecs" :key="item.id" class="col-lg">
                  <i class="bi bi-person-check bi-gris" style="font-size:22px;"></i>&nbsp;<span style="margin-left:8px;"><a class="no-link" @click="getDadesCarrec(item.id)">{{ item['descripcio'+idioma+'_ac'] }}</a></span><br>			                 
              </div>
              <div v-if="dataUnitat['descripcio_utg'+idioma+'_ac']">
                <i class="bi bi-person-check bi-gris" style="font-size:22px;"></i>&nbsp;<span style="margin-left:8px;"><a class="no-link" @click="getDadesCarrec(dataUnitat.idcarrecUTG_txt)">{{ dataUnitat["descripcio_carrec_utg"+idioma+"_ac"] }}</a></span><br>
              </div>            
                      
              <br>
            </div>
            
            <!-- PERSONES -->          
            <div v-if="dataPersones">
              <div class="col-lg contenidorTitolSeccio">
                <div class="titolSeccio" style="color:#000;">{{$t('Personal')}}</div>
                <hr style="margin-top:0px;margin-left:0px;" >
              </div>

              <div v-for="item in dataPersones" :key="item.id" class="col-lg">
                <span style="margin-left:8px;"><a class="no-link" @click="getDadesPersona(item.idgauss_s)">{{ item['cognomsnom_ac'] }}</a></span><br>			
              </div>
            </div> 
          </div> 
          
          <div v-if="data==null" style="margin-top:20px;">            
            <h3>{{$t('__sense_coincidencies__')}}</h3>          
          </div>

        </div>

      </div>
    </div>
    <PeuPagina v-if="endFetch"/>
  </div>

</template>

<script>

const idiomas = {English:"EN",Castellà:"ES",Català:"CA"};
import PeuPagina from "@/components/PeuPagina.vue"
export default {
  components:{
    PeuPagina       
  },

  data: function () {
    return {
      backendURL: process.env.VUE_APP_BACKEND_URL,
      apiURL: 'api',
      data: [],
      dataUnitat: [],
      dataCarrecs: [],
      dataPersones: [],
      idioma: idiomas[this.$i18n.locale], //Utilizado para el idioma de los datos cargados de solr,
      idiomaUrl: "",
      id: this.$route.params.id
    }
  },
 
  created() {
    this.fetchData();
  },

  watch: { 
    '$route.query.searchField': {
      //handler: function(search) {
      handler: function() {
        if(!((this.$route.query.searchField==="") || ((this.$route.params.id)==(this.id)))){
          this.id = this.$route.params.id,
          this.fetchData();
        }        
      },
      deep: true,
      immediate: true
    },    
    '$i18n.locale': {
      handler: function() {
        this.cambiarIdiomaVariables()
      },
      deep: true,
      immediate: true
    
    },
  },

  methods: {
    redireccionInicio(){
      this.$router.push({ path: '/' })
    },
    cambiarIdioma(idioma) {
      this.$i18n.locale = idioma
    },
    cambiarIdiomaVariables() {
      //this.variablesConIdiomaIncluido = "categoria"+idiomas[this.$i18n.locale]+"_txt" //Debe modifcarse cuando se modifica el idioma
      this.idioma = idiomas[this.$i18n.locale]
      this.idiomaUrl = this.idioma.toLowerCase().trim()
    },

    fetchData: async function () {
      try {
        await Promise.all([
          this.fetchSolrData(),
          this.fetchSolrPersonesUnitat(),
        ])
      } catch (error) {
        console.log(error)
      }
    },

    fetchSolrData: function () {

      fetch(`${this.backendURL}/${this.apiURL}/unitatById?id=${this.id}`)
          .then(r => r.json())
          .then(json => {

            if(json.response){

              if(json?.response?.numFound==0){
                this.data=null
                this.endFetch=true
                return
              }

              this.data = json.response?.docs

              let carrecsPosition = 0;
              for(let i=0; i<this.data.length; i++){
                if(this.data[i].nodeType==='carrec'){
                  this.dataCarrecs[carrecsPosition++] = this.data[i]
                } 
                
                if(this.data[i].nodeType==='unitat'){
                  this.dataUnitat = this.data[i]
                }               
              }

              if(this.dataCarrecs.length==0){
                this.dataCarrecs = null
              }              
            }
            else{
              this.data = null
              this.dataCarrecs = null
            }

            
          })
          .catch(error => console.error('Error retrieving DADES_UNITAT_UNITAT_DY_ID: ' + error))
    },

    fetchSolrPersonesUnitat: function () {

      fetch(`${this.backendURL}/${this.apiURL}/personesByIdUnitat?id=${this.id}`)
          .then(r => r.json())
          .then(json => {
          
            this.dataPersones = null; //= json.response.docs

            if(json.response){
              this.dataPersones = json.response.docs
              if(this.dataPersones.length==0){
                this.dataPersones = null
              }            
            }
            else{
              this.dataPersones = null
            }

            this.endFetch=true  

          })
          .catch(error => console.error('Error retrieving DADES_UNITAT_PERSONES_BY_ID_UNITAT: ' + error))
    },

    getDadesPersona: function(idGauss){
      this.$router.push({ path: '/dadesPersona/'+idGauss })
    },
    getDadesCarrec: function(id){
      this.$router.push({ path: '/dadesCarrec/'+id })
    },

    getDadesUnitat: function(id){
      if(!(id==null || id=='undefined')){
        this.$router.push({ path: '/dadesUnitat/U_'+id }).catch(err => {console.log(err)})
      }
      
    },

    getDadesUtg: function(id){
      if(!(id==null || id=='undefined')){
          this.id="U_"+id;
          this.fetchData();
          this.$router.push({ path: '/dadesUnitat/U_'+id }).catch(err => {console.log(err)})
        //this.$router.push({ path: '/dadesUtg/U_'+id }).catch(err => {console.log(err)})       
        
      }

    },

    getTrazaTemporal: function(msg){
      var f1 = new Date().toISOString()
      console.log("NUEVA TRAZA TEMPORAL - "+msg);
      console.log(f1);
    }
  },
}
</script>

<style>

  .open{
    background-color: green;
  }

  .closed{
    background-color: red;
  }
  .bi-gris{
    color: rgb(158, 158, 158);
  }

</style>